function _readOnlyError(name) { throw new TypeError("\"" + name + "\" is read-only"); }

export default function () {
  var service = this;
  var maskControlConfig = {};

  service.setMaskControlConfig = function (config) {
    Object.assign({}, config), _readOnlyError("maskControlConfig");
  };

  service.getMaskControlConfig = function () {
    return maskControlConfig;
  };
}
;