function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function sidebarsContainerService() {
  var service = this;
  var storageContainers = {};
  var storageObserver = {};
  var callbackList = {
    onClose: new Set(),
    onOpen: new Set()
  };

  service.addContainer = function (id, ctrl) {
    return storageContainers['default'] = ctrl;
  };

  service.open = function (options) {
    return storageContainers['default'].open(options);
  };

  service.close = function () {
    return storageContainers['default'].close();
  };

  service.toggle = function (options) {
    return storageContainers['default'].toggle(options);
  };

  service.getState = function () {
    return storageContainers['default'].getState();
  };

  service.addObserverState = function (containerId, contentId, fn) {
    contentId = contentId || 'all';
    storageObserver['default'] = storageObserver['default'] || {};
    storageObserver['default'][contentId] = storageObserver['default'][contentId] || [];
    storageObserver['default'][contentId].push(fn);
  };

  service.processObserver = function (containerId, contentId, data, isOpen) {
    if (storageObserver['default'] != null) {
      if (storageObserver['default'][contentId] != null && storageObserver['default'][contentId].length > 0) {
        storageObserver['default'][contentId].forEach(function (fn) {
          fn(data, isOpen);
        });
      }

      if (storageObserver['default']['all'] != null && storageObserver['default']['all'].length > 0) {
        storageObserver['default']['all'].forEach(function (fn) {
          fn(data, isOpen);
        });
      }
    }
  };

  service.addCallback = function (nameEvent, callback) {
    var needDeleteAfterCall = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var eventList = callbackList[nameEvent];

    if (callback != null && eventList != null) {
      eventList.add({
        callback: callback,
        needDeleteAfterCall: needDeleteAfterCall
      });
    }
  };

  service.callCallbacks = function (nameEvent) {
    var eventList = callbackList[nameEvent];

    if (eventList.size > 0) {
      var _iterator = _createForOfIteratorHelper(eventList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var callbackObj = _step.value;

          if (typeof callbackObj.callback === "function") {
            callbackObj.callback();
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var _iterator2 = _createForOfIteratorHelper(eventList),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _callbackObj = _step2.value;

          if (_callbackObj.needDeleteAfterCall) {
            eventList.delete(_callbackObj);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  }; //service.clearOnCloseCallbacks = function () {
  //    onCloseCallbackList.clear();
  //};

}

;
export default sidebarsContainerService;