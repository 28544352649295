;

(function (ng) {
  'use strict';

  var ModuleCtrl = function ModuleCtrl() {
    var ctrl = this;
  };

  angular.module('module').controller('ModuleCtrl', ModuleCtrl);
  ModuleCtrl.$inject = [];
})(window.angular);